<template>
  <!--
    The Dashboard / home-page (is view; has no component)
  -->
  <Portlet
    :title="$t('homeView.dashboard')"
    class="home"
    icon="tv"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <InstallationsMap />
      <div class="row mt-3">
        <div class="column col-12 col-xl-4 mb-3">
          <div
            v-if="(authenticationHasRole('scope_staff') && !authenticationHasRole('zerolevel'))"
          >
            <Portlet
              :title="$t('homeView.activeSpots')"
              icon="star"
            >
              <template slot="buttons">
                <a
                  class="btn btn-sm btn-secondary"
                  href="https://www.skiline.cc/php/tools/video-overview/?details=true&hourFrom=9&hours=8"
                  target="_blank"
                >{{ $t('homeView.videoOverview') }}</a>
              </template>
              <SkimovieProblemsIssueSpots :issues="activeSpots" />
            </Portlet>
          </div>
          <div
            v-if="(authenticationHasRole('scope_staff') && !authenticationHasRole('zerolevel'))"
            class="mt-3"
          >
            <Portlet
              :title="$t('homeView.activePhotopoints')"
              icon="camera"
            >
              <SkimovieProblemsIssueSpots :issues="activePhotopoints" />
            </Portlet>
          </div>
        </div>
        <div
          v-if="authenticationHasRole('secondlevel') || (authenticationHasRole('scope_staff') && !authenticationHasRole('zerolevel'))"
          :class="authenticationHasRole('zerolevel') || authenticationHasRole('scope_customer') ? '' : 'col-12 col-xl-8'"
        >
          <Portlet       
            title="Installation Summary"
            icon="chart-bar"
          >
            <InstallationReportSummary />
          </Portlet>
          <hr class="m-0 mb-3">
          <Portlet
            v-if="authenticationHasRole('secondlevel') || (authenticationHasRole('scope_staff') && !authenticationHasRole('zerolevel'))"
            portlet-head-class="hide"
            class="d-none"
          >
            <LoadingPlaceholder v-if="iframeLoading" />
            <iframe
              v-show="!iframeLoading"
              :src="`https://www.skiline.cc/php/monitoring/photopoint.php?photopoint=&resort_id=-1&date_from=${ today }&date_until=${ today }#DataTables_Table_0`"
              :onload="iframeLoaded()"
              class="m-0 p-0"
              frameborder="0"
              scrolling="yes"
            />
          </Portlet>
        </div>
      </div>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'
import { setTimeout } from 'timers';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { mobileMixin } from '@/mixins/mobileMixin';

export default {
  name: 'Home',
  components: {
    InstallationsMap: () => import('@/components/InstallationsMap.vue'),
    SkimovieProblemsIssueSpots: () => import('@/components/Problem/SkimovieProblemsIssueSpots.vue'),
    InstallationReportSummary: () => import('@/components/Installation/InstallationReportSummary.vue')
  },
  mixins: [
    dateTimeMixin,
    authenticationMixin,
    mobileMixin
  ],
  data () {
    return {
      loading: true,
      activeSpots: null,
      activePhotopoints: null,
      today: null,
      yesterday: null,
      iframeLoading: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('homeView.dashboard')
    }
  },
  created () {
    if(this.authenticationHasRole('scope_customer') && !this.authenticationHasRole('secondlevel')) {
      this.$router.push({path: `/installation`});
    }
    
    if(this.mobileMixin_isMobile == true && !window.location.pathname.includes('home')) {
      this.$router.push({path: `/installation`});
    }

    this.getData();
    this.getPhotopointData();
    this.today = this.dateTime_date(new Date());
  },
  methods: {
    iframeLoaded () {
      setTimeout(() => {
        this.iframeLoading = false;
      }, 1000)
    },
    getData () {
      this.axios.get('/Report/GetActiveSpots')
        .then((response) => {
          if (response.data) {
            this.activeSpots = response.data;
          }
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
     getPhotopointData () {
      this.axios.get('/Report/GetActivePhotopoints')
        .then((response) => {
          if (response.data) {
            this.activePhotopoints = response.data;
          }
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  height: 450px;
}
</style>
